import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_entry_point } from './entry_point_store'

export const use_gym_modules = defineStore('gym_modules', {
  state: () => ({
    all: null,
  }),

  actions: {
    async create({ gym_module_name }) {
      const { data } = await Vue.smcb_axios.post(`${use_entry_point().fred_base_url}/manage/create-module/${gym_module_name}`)
      return data
    },

    async update({ gym_module_id, enabled }) {
      await Vue.smcb_axios.patch(`${use_entry_point().fred_base_url}/manage/${enabled ? 'enable_module' : 'disable_module'}/${gym_module_id}`)
    },

    async set_invoice({ gym_module }) {
      await Vue.smcb_axios.patch(`${use_entry_point().fred_base_url}/manage/invoice-module/${gym_module.id}`, {
        enabled: gym_module.invoicing,
        published_at: gym_module.published_at,
      })
    },

    async update_module_data({ module_name, data }) {
      const id = this.all[module_name].id
      const response = await Vue.smcb_axios.patch(`/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/gym_modules/${id}`, { id, data })
      const updated_module = response.data
      this.all[module_name] = updated_module
    },
  },

  getters: {
    enabled: state =>
      Object.fromEntries(
        Object.values(state.all)
          .filter(m => m.enabled)
          .map(m => [m.module, true])
      ),

    headcount_calculate_occupancy: state => {
      const data = state.all.headcount?.data
      return data?.enabled && data?.mode === 'slotbooking'
    },

    wages_enabled() {
      return !!this.enabled.wages && !!use_entry_point().flippers.wages
    },

    coursebooking_settings: state => {
      const module = state.all.coursebooking
      const data = module?.data || {}
      data.coursebooking = data.coursebooking || {}
      const days_in_advance = parseInt(data.coursebooking.days_in_advance)
      data.coursebooking.days_in_advance = isNaN(days_in_advance) ? 7 : days_in_advance // Respect 0
      data.coursebooking.max_people_booking = parseInt(data.coursebooking.max_people_booking) || 1
      return data.coursebooking
    },
  },
})
