import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'
import copy_paste_mixin from './copy_paste_mixin'
import editable_mixin from './editable_mixin'

// Copy-pasting across locations makes no sense because of tickets
const vl_gym_id = document.head.querySelector('meta[name="gym-id"]')?.content
const location_specific_cache_key = `slot_defs_${vl_gym_id || 'not_found'}`

function parse_slot_def(slot_def) {
  // We don't process the date fields, better keep them as string!
  slot_def.ticket_ids = slot_def.pricings.map(p => p.id)
  return Object.freeze(slot_def)
}

function parse_list(slot_defs = []) {
  const h = {}
  slot_defs.forEach(def => (h[def.id] = parse_slot_def(def)))
  return h
}

function get_recurrency_params(mode, slot_def) {
  return {
    mode,
    anchor_date: slot_def.slot_date,
  }
}

export const use_slot_defs = defineStore('slot_defs', {
  state: () => ({
    ...copy_paste_mixin.state(location_specific_cache_key),
    ...editable_mixin.state(),

    all_defs_loaded: null,

    // TODO: NQ-2222 Get rid of _all_defs_loaded_by_service by using _defs or splitting store
    _all_defs_loaded_by_service: {},
    _defs: {},

    participants_count: [],
    modal_selected_def_id: null,
    default_slot_def_by_service: {},
  }),

  actions: {
    ...editable_mixin.actions(),
    ...copy_paste_mixin.actions(),

    easy_clipboard_copy(slot_def) {
      this.clipboard_copy(slot_def, ['capacity', 'duration', 'interval', 'is_private', 'open_to_booking', 'pay_later_allowed', 'ticket_required', 'ticket_ids'])
    },

    easy_clipboard_paste() {
      const contents = this.clipboard_paste()
      if (!contents) return

      this.edit = { ...this.edit, ...contents }
    },

    set_all(defs) {
      this._defs = parse_list(defs)
    },

    set_selected(defs) {
      this._defs = parse_list(defs)
      this.all_defs_loaded = false
    },

    async load_all() {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/checkins/slot-defs`)
      const { slot_defs, default_slot_def_by_service } = data
      this.set_all(slot_defs)
      this.default_slot_def_by_service = default_slot_def_by_service
      this.all_defs_loaded = true
    },

    async load_participants_count(from, to) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/checkins/slot-defs/participants/count`, { params: { from, to } })
      this.participants_count = data
    },

    async load_all_in_selected_service() {
      const services = use_services()
      const { data } = await Vue.smcb_axios.get(`${services.base_url_of_selected}/checkins/slot_defs`)

      for (const slot_def of data) {
        this.local_upsert(slot_def)
      }

      Vue.set(this._all_defs_loaded_by_service, services.selected_id, true)
    },

    _update_defs(slot_defs) {
      for (const sd of slot_defs) this.local_upsert(sd)
    },

    async save(slot_def, mode) {
      const services = use_services()
      const params = get_recurrency_params(mode, slot_def)
      this.is_saving = true
      const { data } = await Vue.smcb_axios.put(`${services.base_url_of_service(slot_def.service_id)}/checkins/slot_defs/${slot_def.id}`, slot_def, { params })
      this._update_defs(data)
      this.clear_edit()
    },

    async create(slot_def) {
      const services = use_services()
      this.is_saving = true
      const { data } = await Vue.smcb_axios.post(`${services.base_url_of_service(slot_def.service_id)}/checkins/slot_defs`, slot_def)
      this._update_defs(data)
      this.clear_edit()
    },

    async remove(slot_def, mode) {
      const services = use_services()
      const params = get_recurrency_params(mode, slot_def)
      const { data } = await Vue.smcb_axios.delete(`${services.base_url_of_service(slot_def.service_id)}/checkins/slot_defs/${slot_def.id}`, { params })
      this.local_upsert(data)
    },

    local_upsert(slot_def) {
      if (slot_def.archived_at) {
        Vue.delete(this._defs, slot_def.id)
      } else {
        Vue.set(this._defs, slot_def.id, parse_slot_def(slot_def))
      }
    },

    async save_open_to_booking({ slot_def, open_to_booking }) {
      const services = use_services()
      const { data } = await Vue.smcb_axios.put(`${services.base_url_of_selected}/checkins/slot_defs/${slot_def.id}/open_to_booking`, { open_to_booking })
      this._update_defs(data)
    },
  },

  getters: {
    all: state => Object.values(state._defs),

    all_defs_for_selected_service: state => {
      const services = use_services()
      return state.all_for_service(services.selected_id)
    },

    are_all_defs_loaded_for_selected_service: state => {
      const services = use_services()
      return !!state._all_defs_loaded_by_service[services.selected_id]
    },

    all_for_service: state => service_id => Object.values(state._defs).filter(sd => sd.service_id === service_id),

    from_id: state => slot_def_id => state._defs[slot_def_id],

    modal_selected_def: state => state._defs[state.modal_selected_def_id],

    open: state => Object.values(state._defs).filter(x => x.open_to_booking),

    need_setting_up: state => {
      return !state.open || state.open.length === 0
    },
  },
})
