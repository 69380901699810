import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_entry_point } from './entry_point_store'
import { use_gym_modules } from './gym_modules_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_services } from './services_store'

export function parse_participant(participant) {
  if (participant.arrival_at) participant.arrival_at = Vue.$vl_time.parse_as_local(participant.arrival_at)
  if (participant.checked_in_at) participant.checked_in_at = Vue.$vl_time.parse_as_local(participant.checked_in_at)
  if (participant.must_exit_at) participant.must_exit_at = Vue.$vl_time.parse_as_local(participant.must_exit_at)
  if (participant.checked_out_at) participant.checked_out_at = Vue.$vl_time.parse_as_local(participant.checked_out_at)
  return participant
}

function unpack_booking(booking) {
  const keys = {
    bt: 'bookable_type',
    c: 'customer_id',
    o: 'order_id',
    s: 'status',
  }

  return Vue.$vl_utils.rename_keys(keys, booking)
}

export const use_reservations = defineStore('reservations', {
  state: () => ({
    bookings_by_id: {},
    participant_ids_for_slot: {},
    participants_by_id: {},
    participants_to_load: {},
    slots: {},

    task_groups: [],

    area_group: null,
    current_day: null,
    last_slot_fetching_datetime: null,
    lost_and_found: false,
    skip_ws: null,
    ws_fetching_queue: null,
    areas_defs_groupings: {
      bound: {},
      free_area_ids: [],
    },
  }),

  actions: {
    //
    // Helper method used while loading the page call to diff a participant
    //
    load_participant_if_needed(p, participants_to_load) {
      const existing = this.participants_by_id[p.id]

      if (!existing) {
        if (p.u) {
          Vue.set(this.participants_by_id, p.id, p)
          participants_to_load[p.id] = true
        } else {
          Vue.set(this.participants_by_id, p.id, parse_participant(p))
        }
      } else if (existing.up_at != p.up_at) {
        console.log('VL P!', p.id)
        if (p.u) {
          participants_to_load[p.id] = true
        } else {
          // Not technically needed because do_full_reload is most likely activated
          Vue.set(this.participants_by_id, p.id, parse_participant(p))
        }
      } else if (existing.u) {
        // The slim participant is not here yet. This case is technically not required and adds some
        // overhead / redundancy but makes the system more resilient to for e.g. network errors.
        console.log('VL Pµ', p.id)
        participants_to_load[p.id] = true
      }
    },

    set_participants_photo({ participant_id, image }) {
      const participant = this.participants_by_id[participant_id]
      if (participant) {
        Vue.set(participant, 'photo', image)
        Vue.set(participant, 'photo_path', image.full_image_path)
        Vue.set(participant, 'photo_thumbnail_path', image.full_thumbnail_path)
      }
    },

    clear_participants_photo({ participant_id }) {
      const participant = this.participants_by_id[participant_id]
      if (participant) {
        Vue.set(participant, 'photo', null)
        Vue.set(participant, 'photo_path', null)
        Vue.set(participant, 'photo_thumbnail_path', null)
      }
    },

    clear_slot_reservations({ date, do_full_reload }) {
      // TODO: Converting all date objects from local luxon datetime to a string date representation
      // would avoid some nasty surprises
      if (do_full_reload) this.participants_by_id = {}
      this.current_day = date
      this.participant_ids_for_slot = {}
      this.slots = {}
    },

    set_slot_reservations({ slots, bookings, calendar_participants, lost_and_found, do_full_reload, area_group, areas_defs_groupings, task_groups }) {
      this.areas_defs_groupings = areas_defs_groupings
      this.area_group = area_group?.toString() || null
      this.lost_and_found = lost_and_found || false
      this.task_groups = task_groups || []

      // Update Bookings linked to today's slots
      Object.values(bookings).forEach(unpack_booking)
      this.bookings_by_id = bookings

      // Clear the various dicts
      this.participant_ids_for_slot = {}
      this.slots = {}
      if (do_full_reload) {
        this.participants_by_id = {}
      }

      // Parse all dates within the calendar participants
      Object.keys(calendar_participants).forEach(key => {
        calendar_participants[key] = calendar_participants[key].map(parse_participant)
      })

      const slots_by_time = {}

      // NEW Participant optimization
      const participants_by_id = {}
      const participants_to_load = {}

      // Repopulate with fresh data
      slots.forEach(s => {
        const slot = {
          check_in_at: Vue.$vl_time.parse_as_local(s.slot.check_in_at),
          slot_def_id: s.def_id,
          free_area_spots: s.free,
          is_orphan: !!s.is_orphan,
          is_private: !!s.is_private,
          overbooked_spots: s.over,
          vduration: s.vduration || null,
        }

        Vue.set(this.slots, slot.check_in_at, slot)

        // Participant optimization
        const participant_ids_for_slot = []
        s.participants.forEach(p => {
          if (do_full_reload) {
            if (p.u) {
              participants_by_id[p.id] = p
              participants_to_load[p.id] = true
            } else {
              participants_by_id[p.id] = parse_participant(p)
            }
          } else {
            this.load_participant_if_needed(p, participants_to_load)
          }

          participant_ids_for_slot.push(p.id)
        })
        Vue.set(this.participant_ids_for_slot, slot.check_in_at, participant_ids_for_slot)

        // Gather all slots by time so we can merge the calendar participants in
        slots_by_time[slot.check_in_at.toISO()] = slot
      })

      if (do_full_reload) {
        this.participants_by_id = participants_by_id
      }

      // Assign every calendar participant to the right slot (or create a new one)
      for (let [time, participants] of Object.entries(calendar_participants)) {
        // Add calendar participants to the store's state
        participants.forEach(p => this.load_participant_if_needed(p, participants_to_load))

        // Find the slot for this entry time
        time = Vue.$vl_time.parse_as_local(time)
        const time_str = time.toISO()
        const slot = slots_by_time[time_str]
        if (slot) {
          let slot_participants = this.participant_ids_for_slot[slot.check_in_at]
          slot_participants = [...slot_participants, ...participants.map(p => p.id)]
          Vue.set(this.participant_ids_for_slot, slot.check_in_at, slot_participants)
        } else {
          // Create a calendar-only "synthetic" slot
          const synthetic_slot = {
            id: `synth_${time_str}`,
            check_in_at: time,
            is_orphan: false,
            is_synthetic: true,
            free_area_spots: {},
            overbooked_spots: {},
          }

          slots_by_time[time_str] = synthetic_slot
          Vue.set(this.slots, synthetic_slot.check_in_at, synthetic_slot)
          Vue.set(
            this.participant_ids_for_slot,
            synthetic_slot.check_in_at,
            participants.map(p => p.id)
          )
        }
      }

      this.participants_to_load = participants_to_load
    },

    update_slot_booking(booking) {
      Vue.set(this.bookings_by_id, booking.id, unpack_booking(booking))
    },

    update_participant(participant) {
      Vue.set(this.participants_by_id, participant.id, parse_participant(participant))
    },

    async resend_participant_result_pdf(participant) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant.token}/resend-result`
      const { data } = await Vue.smcb_axios.post(url)
      this.update_participant(data.participant)
    },

    async resend_booking_email(participant) {
      // TODO: NQ-2589 Rename on Wilma too
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant.token}/resend-booking-email`
      await Vue.smcb_axios.post(url)
    },

    async participant_re_check_out(participant_token) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant_token}/re-check-out`
      await Vue.smcb_axios.post(url)
    },

    async ws_silently_fetch_days_slots({ day }) {
      if (this.ws_skip_fetch({ day })) return
      await this.silently_fetch_days_slots()
    },

    async ws_restart_reservations_fetching() {
      if (this.ws_fetching_queue) {
        console.info('FETCH Reservations')
        this.silently_fetch_days_slots()
        this.set_ws_fetching_queue(null)
      }
    },

    async silently_fetch_days_slots(opts) {
      opts = opts || {}
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      let url_action = 'participants'
      const url_params = {}

      // Add area_group to url if on ruled areas
      if (use_services().selected.ruled) {
        url_action = 'participants-for-area-group'

        const old_area_group = this.area_group_clean
        const area_group = opts.area_group || old_area_group || null
        if (area_group) url_params.area_group = area_group
      }

      // Build url
      if (!opts.do_full_reload) url_params.micro = 'true'
      const q = new URLSearchParams(url_params).toString()
      const url = `${use_services().base_url_of_selected}/checkins/${url_action}/${date_path}?${q}`

      const { data } = await Vue.smcb_axios.get(url)
      if (this.current_day.equals(DateTime.fromFormat(date_path, 'yyyy/MM/dd'))) {
        const do_full_reload = opts.do_full_reload || !data.micro
        this.set_slot_reservations({ ...data, do_full_reload })
        this.reset_last_slot_fetching_datetime()
      }
    },

    async fetch_days_slots_with_area_group({ date, area_group }) {
      // When the area_group changes we always do a full reload
      const do_full_reload = true
      this.clear_slot_reservations({ date, do_full_reload })
      await this.silently_fetch_days_slots({ area_group, do_full_reload })
    },

    async fetch_days_slots({ date }) {
      // Before clearing the current state we need to save the current area_group
      let area_group = null
      if (use_services().selected.ruled) {
        area_group = this.area_group_clean
      }

      // If the day changes we do a full reload
      const do_full_reload = this.current_day != date
      this.clear_slot_reservations({ date, do_full_reload })
      await this.silently_fetch_days_slots({ area_group, do_full_reload })
    },

    async ws_silently_fetch_participant({ participant_id }) {
      if (this.ws_skip_fetch({ participant_id })) return
      await this.silently_fetch_participant({ participant_id })
    },

    async silently_fetch_participants_list({ participant_ids }) {
      const url = `${use_smcb_gym().base_url}/checkins/slim-participants`
      const { data } = await Vue.smcb_axios.post(url, { ids: participant_ids })
      for (const participant of data.participants) {
        this.update_participant(participant)
      }
      this.reset_last_slot_fetching_datetime()
    },

    async silently_fetch_participant({ participant_id }) {
      const url = `${use_smcb_gym().base_url}/checkins/slim-participants/${participant_id}`
      const { data } = await Vue.smcb_axios.get(url)
      this.update_participant(data.participant)
      this.reset_last_slot_fetching_datetime()
    },

    async save_time_slot_participant_check_in({ participant_id, participant }) {
      participant = participant || null
      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy
      const { data } = await Vue.smcb_axios.put(`${use_smcb_gym().base_url}/checkins/participants/${participant_id}/check-in`, {
        calculate_occupancy,
        participant,
      })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/headcount/update`, { count: data.occupancy })
      }

      this.update_participant(data.participant)
    },

    async save_time_slot_participant_check_out({ participant_id, participant }) {
      participant = participant || null
      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy
      const { data } = await Vue.smcb_axios.put(`${use_smcb_gym().base_url}/checkins/participants/${participant_id}/check-out`, {
        calculate_occupancy,
        participant,
      })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/headcount/update`, { count: data.occupancy })
      }

      this.update_participant(data.participant)
    },

    async demis_slot_participant_check_out({ participant_id, participant }) {
      participant = participant || null

      const { data } = await Vue.smcb_axios.put(`${use_smcb_gym().base_url}/checkins/participants/${participant_id}/demis-check-out`, {
        participant,
      })

      this.update_participant(data.participant)
    },

    async save_time_slot_participant_cancel({ participant_id, with_email }) {
      with_email = with_email || false
      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy
      let url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/cancel`
      if (with_email) url += '?with_email=1'

      const { data } = await Vue.smcb_axios.put(url, { calculate_occupancy })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/headcount/update`, {
          count: data.occupancy,
        })
      }

      this.update_participant(data.participant)
    },

    async save_time_slot_participant_cancel_with_email({ participant_id }) {
      this.save_time_slot_participant_cancel({ participant_id, with_email: true })
    },

    async save_time_slot_participant_undo_check_in({ participant_id }) {
      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy
      let url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/undo_check-in`
      const { data } = await Vue.smcb_axios.put(url, { calculate_occupancy })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/headcount/update`, {
          count: data.occupancy,
        })
      }

      this.update_participant(data.participant)
    },

    async create_booking(payload) {
      // Payments tickets
      let selected_tickets = []
      payload.booking.participants.forEach(participant => {
        if (participant.ticket && participant.ticket.id) {
          let index = selected_tickets.findIndex(t => t.id === participant.ticket.id)
          if (index < 0) {
            selected_tickets.push({ ...participant.ticket, quantity: 1 })
          } else {
            Vue.set(selected_tickets[index], 'quantity', selected_tickets[index].quantity + 1)
          }
        }
      })

      // Skip the next WS-triggered fetch
      const date = this.current_day
      this.set_skip_fetch_ws({ day: date.toFormat('yyyy-MM-dd') })

      // Call SMCB, if it fails reset WS scheduling
      try {
        const url = `${use_services().base_url_of_selected}/checkins/book`
        payload.booking.selected_tickets = selected_tickets
        await Vue.smcb_axios.post(url, payload)
      } catch (e) {
        this.skip_ws = null
        throw e
      }

      // Now immediately fetch the updated participants
      await this.silently_fetch_days_slots()
    },

    async save_slot_public_booking_disabled({ slot_check_in_at, disabled, current_day }) {
      // Skip the next WS-triggered fetch
      const date = this.current_day
      this.set_skip_fetch_ws({ day: date.toFormat('yyyy-MM-dd') })

      // Call SMCB, if it fails reset WS scheduling
      try {
        const url = `${use_services().base_url_of_selected}/checkins/slot/public_booking`
        await Vue.smcb_axios.put(url, { check_in_at: slot_check_in_at, disabled, current_day })
      } catch (e) {
        this.skip_ws = null
        throw e
      }

      // Now immediately fetch the updated participants
      await this.silently_fetch_days_slots()
    },

    //
    // Update a participant and perhaps also the booking's customer
    //
    async update_checkins_participant_with_customer({ participant, customer }) {
      // Skip the next WS-triggered fetch
      const date = this.current_day
      this.set_skip_fetch_ws({ day: date.toFormat('yyyy-MM-dd'), participant_id: participant.id })

      // Call SMCB, if it fails reset WS scheduling
      let response = null
      try {
        const url = `${use_smcb_gym().base_url}/checkins/participants/${participant.token}/with-customer`
        response = await Vue.smcb_axios.patch(url, { participant, customer })
      } catch (e) {
        this.skip_ws = null
        throw e
      }

      // Now immediately fetch the updated participants
      this.update_participant(response.data.participant)
      this.reset_last_slot_fetching_datetime()
    },

    //
    // Destroy all identifiable participant data
    //
    async anonymize_participant(participant_token) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant_token}/anonymize`
      const { data } = await Vue.smcb_axios.delete(url)
      this.update_participant(data.participant)
    },

    async upload_public_checkins_photo(payload) {
      // TODO: [CGS-1288] Unifiy 2 x upload_public_checkins_photo into one store
      const url = `${use_smcb_gym().base_url}/checkins/upload_photo`
      try {
        const { data } = await Vue.smcb_axios.post(url, payload)
        return data
      } catch (e) {
        const error = { error: true, response: e.response, code: 'unknown' }
        if (e.response.status === 413) error.code = 'file_too_large'
        throw error
      }
    },

    async delete_public_checkins_photo(payload) {
      // TODO: [CGS-1288] Unifiy 2 x delete_public_checkins_photo into one store
      const url = `${use_smcb_gym().base_url}/checkins/delete_photo`
      const response = await Vue.smcb_axios.delete(url, { data: payload })
      return response.data
    },

    async search_participants(query) {
      const services = use_services()
      const { data } = await Vue.smcb_axios.get(`${services.base_url_of_selected}/checkins/participants/search?query=${query}`)
      const service_name_by_id = services.all.reduce((sum, s) => ({ ...sum, [s.id]: s.name }), {})
      const participants = data.map(p => ({ ...p, service_name: service_name_by_id[p.service_id] }))
      return participants.map(parse_participant)
    },

    async stateless_export_reservations_day({ area_id }) {
      const day = this.current_day
      const date_path = day.toFormat('yyyy/MM/dd')
      const query = area_id ? `?area_id=${area_id}` : ''
      const url = `${use_services().base_url_of_selected}/checkins/reservations/${date_path}/export${query}`
      const { data } = await Vue.smcb_axios.get(url)
      return data
    },

    async stateless_export_reservations_slot({ participant_ids }) {
      const url = `${use_smcb_gym().base_url}/checkins/reservations/participants/export`
      const { data } = await Vue.smcb_axios.post(url, { participant_ids })
      return data
    },

    set_ws_fetching_queue(do_update) {
      // TODO: In the future use an actual queue, for now we only keep track of the latest full day update
      if (do_update) console.info('QUEUE Reservations')
      this.ws_fetching_queue = do_update
    },

    reset_last_slot_fetching_datetime() {
      this.last_slot_fetching_datetime = DateTime.local()
    },

    set_skip_fetch_ws({ day, booking_id, participant_id }) {
      this.skip_ws = {
        deadline: new Date().getTime() + 60000,
        day,
        booking_id,
        participant_id,
      }
    },

    //
    // Returns true if the reservations WS can skip the data fetching (avoids redundant calls)
    //
    ws_skip_fetch({ day, participant_id, booking_id }) {
      if (!this.skip_ws) return false

      const now = new Date().getTime()
      let result = false
      if (this.skip_ws.deadline < now) result = false
      else if (booking_id && booking_id === this.skip_ws.booking_id) result = true
      else if (participant_id && participant_id === this.skip_ws.participant_id) result = true
      else if (day && day === this.skip_ws.day) result = true

      console.info('Skip WS update', { skip_ws: this.skip_ws, day, result })
      this.skip_ws = null

      return result
    },

    async cancel_participant({ participant_id, with_email }) {
      with_email = with_email || false

      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy

      let url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/cancel`
      if (with_email) url += '?with_email=1'

      const { data } = await Vue.smcb_axios.put(url, { calculate_occupancy })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`${use_entry_point().fred_base_url}/headcount/update`, {
          count: data.occupancy,
        })
      }

      this.update_participant(data.participant)
    },

    get_slot_booking_participants(participant, booking) {
      const slot_participants = this.get_slot_participants(participant.arrival_at)
      return slot_participants.filter(participant => participant.booking_id === booking.id)
    },

    async load_booking_by_id(id) {
      const { data } = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/checkins/slim_booking/${id}`)
      this.update_slot_booking(data)
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/checkins`,
    participant_url: () =>
      function (participant_identifier) {
        return `${this.base_url}/participants/${participant_identifier}`
      },

    get_slot_participants: state => slot_check_in_at => {
      const participant_ids = state.participant_ids_for_slot[slot_check_in_at]
      return participant_ids.map(p_id => state.participants_by_id[p_id])
    },

    get_slot_booking_by_id: state => booking_id => {
      return state.bookings_by_id[booking_id] || null
    },

    get_slim_participant_by_id: state => id => {
      return state.participants_by_id[id]
    },

    get_current_days_slot_units: state => {
      const slots = Vue.$vl_utils.sort_by(Object.values(state.slots), x => x.check_in_at)
      return slots.map(slot => {
        const participants = state.get_slot_participants(slot.check_in_at)
        return { slot, participants }
      })
    },

    task_group_from_id: state => task_group_id => {
      return state.task_groups.find(tg => tg.id === task_group_id)
    },

    available_areas: state => {
      let areas = use_services().of_selected.areas
      if (state.area_group === 'FREE') {
        return areas.filter(area => state.areas_defs_groupings.free_area_ids.includes(area.id))
      } else if (!state.area_group) {
        return areas
      } else {
        return areas.filter(area => parseInt(state.area_group) === area.id)
      }
    },

    area_group_clean: state => {
      if (state.area_group === 'ORPHANS') return null
      return state.area_group
    },
  },
})
