import Vue from 'vue'

import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

const DEVICE_TOKEN = 'device-token'

export const use_devices = defineStore('devices', {
  state: () => ({
    device: {},
    has_device: null,
  }),

  actions: {
    async fetch_device() {
      const url = `${use_smcb_gym().base_url}/signatures/device`
      const { data } = await Vue.smcb_axios.get(url)
      this.device = data
    },

    async remove_device() {
      const url = `${use_smcb_gym().base_url}/signatures/device/${this.device.uuid}`
      await Vue.smcb_axios.delete(url)
      this.device = {}
    },

    check_device_token() {
      return this.get_device_token() !== null
    },

    get_device_token() {
      return localStorage.getItem(DEVICE_TOKEN)
    },
  },
})
