import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'
//NQ-3101 I think this can be removed
function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })

  return blob
}

export const use_dcc = defineStore('dcc_store', {
  state: () => ({
    test_kits: null,
  }),

  actions: {
    async generate_dcc_certificate({ participant_id }) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/generate_dcc_cert`
      const response = await Vue.smcb_axios.get(url)

      const newBlob = b64toBlob(response.data.b64, 'application/pdf')

      // For IE
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      // For other browsers
      const data = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = data
      link.download = response.data.filename
      link.click()
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
})
