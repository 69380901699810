//
// Example of deep whitelist format:
//
// ['firstname', 'lastname', { extra_fields: ['document_type', 'document_city'] } ]
//

function copy_one_level(obj, whitelist) {
  const new_obj = {}
  for (let x of whitelist) {
    if (typeof x === 'string') {
      new_obj[x] = obj[x]
    } else {
      for (const [key, sublist] of Object.entries(x)) {
        new_obj[key] = copy_one_level(obj[key], sublist)
      }
    }
  }
  return new_obj
}

export default {
  state(key) {
    return {
      clipboard_key: `clipboard_${key}`,
    }
  },

  actions() {
    return {
      clipboard_busy() {
        return !!localStorage.getItem(this.clipboard_key)
      },

      clipboard_copy(obj, whitelist) {
        const value = copy_one_level(obj, whitelist)

        localStorage.setItem(
          this.clipboard_key,
          JSON.stringify({
            value,
            expiry: new Date().getTime() + 60 * 60 * 1000,
          })
        )
      },

      clipboard_paste() {
        let data = localStorage.getItem(this.clipboard_key)
        if (!data) return null
        data = JSON.parse(data)
        return data?.value
      },
    }
  },
}
