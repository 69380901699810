import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_service_documents } from './service_documents_store'
import { use_slot_defs } from './slot_defs_store'
import { use_smcb_gym } from './smcb_gym_store'

const extra_services = ['antigen', 'pcr', 'vax-covid', 'medication', 'organ-transplant', 'cancer-treatment', 'antibody', 'eucerin-skin-consulting']

function parse_service(service) {
  service._title = service.title_i18n[I18n.locale] || service.title_i18n.en
  return service
}

export const use_services = defineStore('services', {
  state: () => ({
    all: null,
    sorting: false,

    // Depending on selected service
    selected: null,
    of_selected: {
      archived_areas: null,
      areas: null, // Active areas only
      check_in_clock: null,
      flags: {
        dcc_enabled: null,
        is_in_resource: null,
      },
      integration_hooks: null,
      integration_objects: null,
      participants_exist: null,
      settings: null,
      test_cost: null,
      uses_ticket: null,
    },
  }),

  actions: {
    set_all(services) {
      this.all = services.map(service => parse_service(service))
    },

    set_selected(service) {
      this.selected = parse_service(service)
    },

    async activate({ name, make_active }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/activate-service/${name}`
      const { data } = await Vue.smcb_axios.patch(url, { make_active })
      await this.set_all(data.all_services)
    },

    async add_slot_area(slot_area) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url_of_selected}/checkins/create-slot-area`, { slot_area })
      this.set_areas(data)
    },

    async save_slot_areas(slot_areas) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/checkins/update-slot-areas`, { slot_areas })
      this.set_areas(data)
    },

    async remove_slot_area(slot_area) {
      await Vue.smcb_axios.patch(`${this.base_url_of_selected}/checkins/remove-slot-area`, { slot_area })
      const idx = this.of_selected.areas.findIndex(a => a.id === slot_area.id)
      Vue.delete(this.of_selected.areas, idx)
    },

    async publish({ service_id }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/publish-service/${service_id}`
      const { data } = await Vue.smcb_axios.patch(url)
      await this.set_all(data.all_services)
    },

    async unpublish({ service_id }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/unpublish-service/${service_id}`
      const { data } = await Vue.smcb_axios.patch(url)
      await this.set_all(data.all_services)
    },

    async create_custom({ title_i18n, icon }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/custom-service`
      const { data } = await Vue.smcb_axios.post(url, { title_i18n, icon })
      await this.set_all(data.all_services)
    },

    async update_title({ id, title_i18n, icon }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/update-title`
      const { data } = await Vue.smcb_axios.patch(url, { id, title_i18n, icon })
      await this.set_all(data.all_services)
    },

    async update_color({ id, color }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/update-color`
      const { data } = await Vue.smcb_axios.patch(url, { id, color })
      await this.set_all(data.all_services)
    },

    async update_integration_objects(integration_objects) {
      const ios = integration_objects.map(io => {
        if (io.type === 'select-with-value' && io.data.hasOwnProperty('fields')) {
          const list = io.data.list ? io.data.list.map(l => ({ ...l, value: JSON.parse(l.value) })) : []
          return { ...io, data: { ...io.data, list } }
        }
        return io
      })

      // TODO: use Buffer.from(str, 'base64') instead of btoa? (check deprecation msg)
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/integration-objects-update-all`, {
        iobs_b64: btoa(encodeURIComponent(JSON.stringify(ios))),
      })
      this.of_selected.integration_objects = data.integration_objects
    },

    async update_integration_object_flags(io) {
      try {
        const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/integration-objects-update-flags`, io)
        Vue.set(this.of_selected.integration_objects, io.key, data.iobject)
        Vue.$vl_utils.show_success_alert(Vue.$i18n.t('form.saved'))
      } catch (e) {
        Vue.$vl_utils.show_error_alert(Vue.$i18n.t('form.error_while_saving'), e)
      }
    },

    commit_integration_object(iobject) {
      try {
        Vue.set(this.of_selected.integration_objects, iobject.key, iobject)
        Vue.$vl_utils.show_success_alert(Vue.$i18n.t('form.saved'))
      } catch (e) {
        Vue.$vl_utils.show_error_alert(Vue.$i18n.t('form.error_while_saving'), e)
      }
    },

    commit_integration_hook(ihook) {
      Vue.set(
        this.of_selected.integration_hooks,
        this.of_selected.integration_hooks.findIndex(io => io.id === ihook.id),
        ihook
      )
    },

    async update_integration_object(iobject) {
      if (iobject.type === 'select-with-value' && iobject.data.hasOwnProperty('fields')) {
        const list = iobject.data.list ? iobject.data.list.map(l => ({ ...l, value: JSON.parse(l.value) })) : []
        iobject = { ...iobject, data: { ...iobject.data, list } }
      }

      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/integration-objects-update`, iobject)
      this.commit_integration_object(data.iobject)
    },

    async create_integration_object(iobject) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url_of_selected}/integration-objects`, iobject)
      this.commit_integration_object(data.iobject)
    },

    async delete_integration_object(iobject) {
      const { data } = await Vue.smcb_axios.delete(`${this.base_url_of_selected}/integration-objects/${iobject.id}`)
      Vue.delete(this.of_selected.integration_objects, iobject.key)
    },

    async enable_dcc_integration() {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/checkins/dcc/enable`)

      if (data) {
        this.commit_integration_hook(data.integration_hook)
        this.of_selected.integration_objects = data.integration_objects
      }
    },

    async enable_italian_pharma_test_kits() {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/checkins/dcc/enable_italian_pharma`)

      if (data) {
        this.commit_integration_hook(data.integration_hook)
        this.of_selected.integration_objects = data.integration_objects
      }
    },

    async disable_dcc_integration() {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/checkins/dcc/disable`)

      if (data) {
        this.commit_integration_hook(data.integration_hook)
        this.of_selected.integration_objects = data.integration_objects
      }
    },

    async update_settings(settings) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/service-settings`, settings)
      this.of_selected.settings = data.settings
    },

    async trigger_participants_password_update() {
      await Vue.smcb_axios.post(`${this.base_url_of_selected}/checkins/update-future-passwords`)
    },

    async update_integration_hook(data) {
      const response = await Vue.smcb_axios.patch(`${this.base_url_of_selected}/integration_hooks/`, data)
      this.commit_integration_hook(response.data)
    },

    mark_setup_step_as_done(step) {
      const settings = this.of_selected.settings
      const setup_settings = { ...settings.setup } || {}
      if (setup_settings[step]) return // already marked as done
      setup_settings[step] = true

      return this.update_settings({
        ...settings,
        setup: setup_settings,
      })
    },

    //
    // NOTE: Depending on the page that loads it, the areas may or may not include the archived ones.
    // Components that depend on the archived areas being loaded should take care of loading these at boot.
    //
    set_areas(areas) {
      areas = Vue.$vl_utils.sort_by(areas, a => a.order)
      this.of_selected.areas = areas.filter(a => !a.archived_at)

      const archived_areas = areas.filter(a => a.archived_at)
      if (archived_areas.length > 0) {
        this.of_selected.archived_areas = archived_areas
      }
    },

    update_one_area(area) {
      const ix = this.of_selected.areas.findIndex(a => a.id == area.id)
      Vue.set(this.of_selected.areas, ix, area)
    },

    async order({ ordered_ids }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/sort-services/`
      const { data } = await Vue.smcb_axios.patch(url, { ordered_ids })
      await this.set_all(data.all_services)
    },

    async order_iobjs({ ordered_ids }) {
      const url = `${this.base_url_of_selected}/integration-objects-sort`
      const { data } = await Vue.smcb_axios.patch(url, { ordered_ids })
      this.of_selected.integration_objects = data.integration_objects
    },

    get_status(service) {
      // A) no service DB
      // B) is_active=0 is_public=0 is_ready=0
      // C) is_active=0 is_public=0 is_ready=1
      // D) is_active=1 is_public=0 is_ready=0
      // E) is_active=1 is_public=0 is_ready=1
      // F) is_active=1 is_public=1
      if (!service) return 'A'

      const smcb_gym = use_smcb_gym()
      const ready_services = smcb_gym.services_setup_progress.ready
      const is_ready = ready_services.includes(service.name)

      if (!service.is_active) return is_ready ? 'C' : 'B'
      if (!service.is_public) return is_ready ? 'E' : 'D'
      return 'F'
    },

    hash_service_for_sorting(order, status) {
      return `${(order || 0).toString().padStart(2, '0')}${'F'.charCodeAt(0) - status.charCodeAt(0)}`
    },

    async update_area_color({ service_id, area_id, color }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/services/${service_id}/checkins/areas/${area_id}/update-color`
      const { data } = await Vue.smcb_axios.patch(url, { color })
      this.update_one_area(data.area)
    },
  },

  getters: {
    active: state => {
      return state.all?.filter(s => s.is_active) || []
    },

    active_and_sorted() {
      const [sortable_services, non_sortable_services] = Vue.$vl_utils.partition(this.active, s => Vue.$vl_utils.is_number(s.order))
      const sorted_services = Vue.$vl_utils.sort_by(sortable_services, s => this.hash_service_for_sorting(s.order, this.get_status(s)))
      return [...sorted_services, ...non_sortable_services]
    },

    selected_id(state) {
      if (!state.selected) return null
      return state.selected.id
    },

    base_url_of_selected(state) {
      if (!state.selected) return null
      return this.base_url_of_service(this.selected_id)
    },

    base_url_of_service: () => service_id => {
      const smcb_gym = use_smcb_gym()
      return `${smcb_gym.base_url}/services/${service_id}`
    },

    is_german_antigen: state => {
      const s = state.selected
      return s && s.context === 'germany-pharma' && s.name === 'antigen'
    },

    is_german_pcr: state => {
      const s = state.selected
      return s && s.context === 'germany-pharma' && s.name === 'pcr'
    },

    is_vax: state => {
      const s = state.selected
      return s && s.name.startsWith('vax-')
    },

    is_german_vax: state => {
      const s = state.selected
      return s && s.context === 'germany-pharma' && ['vax-covid', 'vax-flu'].includes(s.name)
    },

    is_german_covid_vax: state => {
      const s = state.selected
      return s && s.context === 'germany-pharma' && s.name === 'vax-covid'
    },

    is_south_tyrol_covid_vax: state => {
      const s = state.selected
      return s && s.context === 'south-tyrol-pharma' && s.name === 'vax-covid'
    },

    from_id: state => id => state.all.find(s => s.id === id),

    area_from_id: state => id => {
      return state.of_selected.areas.find(a => a.id === id) || state.of_selected.archived_areas?.find(a => a.id === id)
    },

    from_name: state => name => state.all.find(s => s.name === name),

    extra_services_names: state => {
      return extra_services
    },

    is_german_pharma_extra_service: state => {
      return extra_services.includes(state.selected.name)
    },

    participant_fields: state => {
      const iobjs = Object.values(state.of_selected.integration_objects).filter(o => o.category === 'participant_field')
      const fields = {}
      iobjs.forEach(o => {
        fields[o.key] = o
      })
      return fields
    },

    areas_need_setting_up: state => {
      const areas = state.of_selected.areas
      if (!areas || areas.length === 0) return true
      return false
    },

    needs_setting_up: state => {
      if (state.of_selected.participants_exist) return false
      return state.areas_need_setting_up || use_slot_defs().need_setting_up || !state.selected.is_public
    },

    get_custom_label_translation: state => status => {
      const service_context = state.selected.context.replace('-', '_')
      const service_name = state.selected.name.replace('-', '_')

      if (status === 'check_out' && !use_service_documents().all_result_doc_hidden) {
        status = 'check_out_with_result'
      }

      if (Vue.$i18n.te(`checkins.reservations.labels.${service_context}.${service_name}.${status}`)) {
        return Vue.$i18n.t(`checkins.reservations.labels.${service_context}.${service_name}.${status}`)
      } else if (['antigen', 'pcr', 'antibody'].includes(service_name) && Vue.$i18n.te(`checkins.reservations.labels.test.${status}`)) {
        return Vue.$i18n.t(`checkins.reservations.labels.test.${status}`)
      } else if (service_name.startsWith('vax_') && Vue.$i18n.te(`checkins.reservations.labels.vax.${status}`)) {
        return Vue.$i18n.t(`checkins.reservations.labels.vax.${status}`)
      }

      return null
    },

    mandatory_fields_before_action: state => {
      const mandatory_fields = structuredClone(state.of_selected.settings.mandatory_fields_before_action)

      if (mandatory_fields?.check_in) {
        mandatory_fields.check_out = [...new Set(mandatory_fields.check_in.concat(mandatory_fields.check_out || []))]
      }

      return mandatory_fields
    },

    get_service_icon: state => service_name => {
      const service = state.all.find(s => s.name === service_name)
      if (!service) return

      if (service.icon) return service.icon

      return (
        {
          antigen: 'antigen',
          pcr: 'covid19',
          antibody: 'antibody',
          'vax-covid': 'vax-covid',
          'vax-flu': 'vax-flu',
          medication: 'medication',
          'blood-pressure': 'blood-pressure',
          'vitamin-d': 'vitamin-d',
          inhalation: 'inhalation',
          'organ-transplant': 'organ-transplant',
          'cancer-treatment': 'cancer-treatment',
          'individual-consultation': 'individual-consultation',
          'eucerin-skin-consulting': 'eucerin-skin-consulting',
        }[service.name] || 'exchange'
      )
    },
  },
})
