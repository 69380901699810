import { defineStore } from 'pinia'
import Vue from 'vue'

export const use_employee_basic = defineStore('employee_basic', {
  state: () => ({
    employees: {},
  }),

  actions: {
    set(list) {
      const h = {}
      for (const employee of list) h[employee.id] = employee
      this.employees = h
    },

    manage_superadmin(employee) {
      Vue.set(this.employees, employee.id, employee)
    },
  },

  getters: {
    list: state => Object.values(state.employees),
  },
})
