import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_entry_point } from './entry_point_store'
import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'

export const use_actor = defineStore('actor', {
  state: () => ({
    employee: null,
  }),

  actions: {
    can_view_service(service_name) {
      if (!this.employee) return false
      const service = use_services().from_name(service_name)
      if (!service || !service.is_active) return false
      if (this.can_superadmin) return true
      return ['view', 'manage'].includes(this.permissions_by_service_name[service.name]?.value)
    },

    can_manage_service(service_name) {
      if (!this.employee) return false
      const service = use_services().from_name(service_name)
      if (!service || !service.is_active) return false
      if (this.can_superadmin) return true
      return this.permissions_by_service_name[service.name]?.value === 'manage'
    },

    async fetch_fat_staff_employee() {
      const response = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/employees/me`)
      if (response.data && response.status === 200) {
        this.employee = response.data
      }
    },
  },

  getters: {
    // TODO: NQ-2000 DEPRECATED, get rid after reaching master
    role: state => role => {
      const roles = ['employee', 'editor', 'admin', 'superadmin']
      const ix = roles.indexOf(role)
      return ix > -1 && !!state.employee && roles.indexOf(state.employee.role) >= ix
    },

    permissions_by_service_name() {
      if (!this.employee) return {}
      return this.employee.permissions?.services || {}
    },

    can_view_selected_service() {
      const services = use_services()
      if (!services.selected_id) return false
      const selected_service_name = services.from_id(services.selected_id).name
      return this.can_view_service(selected_service_name)
    },

    can_manage_selected_service() {
      const services = use_services()
      if (!services.selected_id) return false
      const selected_service_name = services.from_id(services.selected_id).name
      return this.can_manage_service(selected_service_name)
    },

    is_vl_employee: state => state.employee?.vl_employee,
    is_vl_core_admin: state => use_entry_point().flippers.vl_core_admin,

    can_admin: state => ['admin', 'superadmin'].includes(state.employee?.role),
    can_editor: state => ['editor', 'admin', 'superadmin'].includes(state.employee?.role),
    can_superadmin: state => state.employee?.role === 'superadmin',

    visible_services() {
      const services = use_services()
      return services.active.filter(s => this.can_view_service(s.name))
    },

    managed_services() {
      const services = use_services()
      return services.active.filter(s => this.can_manage_service(s.name))
    },

    can_start_video_consultation() {
      if (!this.employee) return false
      return this.employee.permissions?.video_consultation?.value === 'view'
    },

    can_view_qrm() {
      if (!this.employee) return false
      const service = use_services().from_name('antigen')
      if (!service) return false
      if (!service.is_active) return false
      if (this.can_superadmin) return true
      return this.employee.permissions?.services?.antigen?.checkins_qrm === true
    },
  },
})
