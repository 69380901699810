import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_services } from './services_store'
import { use_devices } from './devices_store'

export const use_service_documents = defineStore('service_documents', {
  state: () => ({
    all: [],
    archived_count: 0,
    sorting: false,
  }),

  actions: {
    async fetch() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url_of_selected_service}/active`)
      this.all = data.documents
      this.archived_count = data.archived
      use_devices().has_device = data.has_device
    },

    async add_document(document) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url_of_selected_service}/add-document`, document)
      this.all.push(data)
    },

    async toggle_document_attribute(document, key) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/toggle-document-attribute/${key}`, document)

      const idx = this.all.findIndex(d => d.id === document.id && d.custom === document.custom)
      Vue.set(this.all, idx, { ...this.all[idx], [key]: data.value })
    },

    async archive(document) {
      await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/archive`, document)

      const idx = this.all.findIndex(d => d.id === document.id && d.custom === false)
      Vue.delete(this.all, idx)

      const count = (this.archived_count[document.event] || 0) + 1
      Vue.set(this.archived_count, document.event, count)
    },

    async unarchive(event) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/unarchive`, { event })

      this.all = this.all.concat(data.documents)
      Vue.set(this.archived_count, event, 0)
    },

    async remove(document) {
      await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/remove`, document)

      const idx = this.all.findIndex(d => d.id === document.id && d.custom === true)
      Vue.delete(this.all, idx)
    },

    async update(document) {
      await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/update`, document)

      const idx = this.all.findIndex(d => d.id === document.id && d.custom === document.custom)
      Vue.set(this.all, idx, { ...this.all[idx], name: document.name, url: document.url })
    },

    async order({ documents }) {
      await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/order`, { documents })
      this.all = documents
    },

    async toggle_publish({ document, action, current_doc_uuid = null, include_in_email = null }) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url_of_selected_service}/${action}`, {
        document_uuid: document.uuid,
        current_doc_uuid,
        include_in_email,
      })

      if (current_doc_uuid) {
        const idx = this.all.findIndex(d => d.uuid === document.uuid)
        Vue.delete(this.all, idx)
      }

      const idx = this.all.findIndex(d => d.uuid === data.uuid)
      Vue.set(this.all, idx, data)
    },
  },

  getters: {
    base_url_of_selected_service() {
      return `${use_services().base_url_of_selected}/checkins/documents`
    },

    existing_names() {
      return this.all.map(d => d.name)
    },

    all_result_doc_hidden(state) {
      return state.all.filter(d => d.event === 'check-out' && d.published !== false && !d.hide_from_participant).length === 0
    },
  },
})
