import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_entry_point } from './entry_point_store'

export const use_smcb_gym = defineStore('smcb_gym', {
  state: () => ({
    gym_options: {},
    flags: {
      auto_check_out: null,
      is_german_pharma: null,
    },
    services_setup_progress: {},
    settings: null,
    url: '',
  }),

  actions: {
    async update_settings(settings) {
      const url = `${this.base_url}/gym-settings`
      const { data } = await Vue.smcb_axios.patch(url, { settings })
      this.settings = data
    },

    async refetch_settings() {
      const url = `${this.base_url}/gym-settings`
      const { data } = await Vue.smcb_axios.get(url)
      this.settings = data
    },

    async activate_gym_option({ name, is_on }) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url}/gym-options/toggle`, { name, is_on })
      this.gym_options = data.active_gym_options
    },

    // TODO: [NQ-2212] Needs to become a gym_setting
    async update_auto_checkout(auto_check_out) {
      const url = `${this.base_url}/checkins/auto-check-out`
      const { data } = await Vue.smcb_axios.patch(url, { auto_check_out })
      this.flags.auto_check_out = data.auto_check_out
    },
  },

  getters: {
    base_url: state => {
      const entry_point = use_entry_point()
      return `${state.url}/gyms/${entry_point.gym.id}`
    },

    show_direct_status_change_buttons: state => {
      if (!Vue.$vl_utils.is_running_noq()) return false
      if (state.gym_options.hide_direct_status_change_buttons) return false

      return true
    },

    disable_multi_participant_bookings: state => {
      if (!Vue.$vl_utils.is_running_noq()) return false
      if (state.gym_options.allow_multi_participant_bookings) return false

      return true
    },

    hide_checkout_timer: state => {
      if (!Vue.$vl_utils.is_running_noq()) return false
      if (state.gym_options.show_checkout_timer) return false

      return true
    },
  },
})
