import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export const use_sidemenu_counters = defineStore('sidemenu_counters', {
  state: () => ({
    counters: {},
  }),

  actions: {
    async load_prescriptions() {
      const smcb_gym = use_smcb_gym()

      const response = await Vue.smcb_axios.get(`${smcb_gym.base_url}/prescriptions/notifications`)
      if (response && response.status === 200 && response.data) {
        Vue.set(this.counters, 'prescriptions_new', response.data.new_order_count)
      }
    },

    set_booking_counters(booking_counters) {
      for (const [service_name, count] of Object.entries(booking_counters)) {
        Vue.set(this.counters, `checkins-${service_name}-bookings`, count || 0)
      }
    },
  },

  getters: {
    total: state => {
      let total = 0
      Object.keys(state.counters).forEach(k => (total += state.counters[k]))
      return total
    },
  },
})
