import Vue from 'vue'

export default {
  state() {
    return {
      is_editing: false,
      is_saving: false,
      edit: null,
      edit_original: null,
    }
  },

  actions() {
    return {
      update_edit(obj) {
        this.edit_original = structuredClone(obj)
        this.edit = structuredClone(obj)
        this.is_editing = true
        this.is_saving = false
      },

      async clear_edit() {
        this.is_editing = false
        this.is_saving = false
        await Vue.nextTick()
        this.edit = null
      },
    }
  },
}
