import { defineStore } from 'pinia'

import { IdleJs } from '@assets/javascripts/idle-js.js'
import { use_entry_point } from './entry_point_store'
import { use_smcb_gym } from './smcb_gym_store'

function intialize_idle_js(store, { actions, cable }) {
  const icon = document.getElementById('human-is-idle-icon')
  const opts = use_smcb_gym().gym_options
  const mode_is_active = opts && opts.human_is_idle_mode
  store.mode_is_active = mode_is_active

  function on_idle() {
    console.info('VL Human is idle')
    store.is_idle = true
    icon.className = icon.className.replace(' d-none', ' d-block')
    if (actions.on_hidle) actions.on_hidle()
  }

  function on_active() {
    console.info('VL Human is active')
    store.is_idle = false
    icon.className = icon.className.replace(' d-block', ' d-none')
    if (actions.on_active) actions.on_active()
  }

  function build_idlejs() {
    console.log(store.human_is_idle_timeout * 1000)
    return new IdleJs({
      idle: store.human_is_idle_timeout * 1000, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: on_idle, // callback function to be executed after idle time
      onActive: on_active, // callback function to be executed after back form idleness
      onHide: on_idle, // callback function to be executed when window become hidden
      onShow: on_active, // callback function to be executed when window become visible
      keepTracking: true, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: false, // set it to true if you want to start in the idle state
    })
  }

  let idle = build_idlejs()

  if (store.mode_is_active) {
    idle.start()
  }

  //
  // Setup WS updates
  //
  if (cable) {
    cable.subscriptions.create(
      { channel: 'SettingsStreamChannel', gym_id: use_entry_point().gym.id },
      {
        received: data => {
          console.info('VL SettingsStreamChannel', data)
          if (data.ctx === 'gym_option' && data.key === 'human_is_idle_mode') {
            store.mode_is_active = data.val

            if (store.mode_is_active) {
              idle.start()
            } else {
              idle.stop().reset()
              if (store.is_idle) on_active(true)
            }
          } else if (data.ctx === 'global_setting' && data.key === 'human_is_idle_timeout') {
            // Store the new timeout value
            store.idle_timeout = data.val

            // Replace existing IdleJs object
            idle.stop()
            idle = build_idlejs()

            // Start IdleJs if mode is active
            if (store.mode_is_active) idle.start()
          }
        },
      }
    )
  }
}

export const use_human_is_idle = defineStore('human_is_idle', {
  state: () => ({
    is_idle: false,
    mode_is_active: false,
    idle_timeout: null,
  }),

  actions: {
    init({ actions, cable }) {
      intialize_idle_js(this, { actions, cable })
    },
  },

  getters: {
    human_is_idle: state => state.mode_is_active && state.is_idle,
    human_is_idle_timeout: state => state.idle_timeout || 60,
  },
})
