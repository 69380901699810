import { defineStore } from 'pinia'
import { use_actor } from './actor_store'
import { use_entry_point } from './entry_point_store'
import { use_gym_modules } from './gym_modules_store'
import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'

export const use_sidemenu_rules = defineStore('sidemenu_rules', {
  getters: {
    show_menu: () => {
      const actor = use_actor()
      const entry_point = use_entry_point()
      const gym_modules = use_gym_modules()
      const services = use_services()
      const smcb_gym = use_smcb_gym()

      // Modules
      const headcount_module = gym_modules.enabled.headcount
      const ihre_apo = smcb_gym.gym_options.uses_ihre_apotheken && gym_modules.enabled.ihre_apotheken
      const smcb_module = gym_modules.enabled.smcb
      const wages_module = gym_modules.enabled.wages

      // Service-specific
      const ss = services.selected
      const has_vax_service = ss && ss.name.includes('vax-')
      const is_multiservice = services.active.length > 1

      // IHooks-based
      const ihs = services.of_selected.integration_hooks

      return {
        gym_checkins_capacity_overview: actor.can_admin,
        gym_checkins_gdpr_requests: actor.can_superadmin && entry_point.flippers.gdpr_requests,
        gym_checkins_services: smcb_gym.gym_options.self_managed_services,
        gym_checkins_vax_overview: actor.can_admin && has_vax_service,
        gym_checkins_vouchers: actor.can_admin && smcb_gym.gym_options.uses_vouchers,
        gym_checkins_extra_texts: actor.can_admin && is_multiservice,
        gym_ihre_apotheken: actor.can_admin && ihre_apo,
        gym_use_signature_service: actor.can_admin && smcb_gym.gym_options.use_signature_service,
        role_admin: actor.can_admin,
        role_editor: actor.can_editor,
        show_headcount: headcount_module && actor.can_editor,
        show_rental: actor.can_editor && smcb_gym.gym_options.uses_rental,
        show_staff: smcb_module,
        show_wages: smcb_module && wages_module && actor.can_admin,
      }
    },
  },
})
